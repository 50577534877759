.appFormFileItem {
  background-color: #fcfcfc;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appFormFileItem + .appFormFileItem {
  margin-top: 10px;
}

.appFormImageContainer {
  max-width: 100%;
  height: 100%;
  padding: 10px;
  flex: 1 1 100%;
  text-align: center;
}

.appFormImageContainer img {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 100%;
}

.appFormFileControllers {
  display: flex;
  flex: 0 0 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.appFormFileControllersButton {
  height: 25px;
  cursor: pointer;
}

.appFormFileControllers img {
  width: 25px;
}

.appFormVideoContainer {
  width: 100%;
}

.appFormVideoContainer input[type="text"] {
  width: 100%
}

/**
 * Boutons "ajouter une image / vidéo"
 */

.appFormAddRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.appFormAddButtonContainer {
  width: 50%;
}

.appFormAddRow .appFormAddButtonContainer:first-child {
  padding-right: 10px;
}

.appFormAddRow .appFormAddButtonContainer:nth-child(2) {
  padding-left: 10px;
}

@media screen and (max-width: 650px) {
  .appFormAddRow {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .appFormAddButtonContainer {
    width: 100%;
  }
  
  .appFormAddRow .appFormAddButtonContainer:first-child {
    padding-right: 0;
  }
  
  .appFormAddRow .appFormAddButtonContainer:nth-child(2) {
    margin-top: 10px;
    padding-left: 0;
  }
}

@media screen and (max-width: 380px) {
  .appFormAddButtonContainer button {
    font-size: 14px !important;
  }
}
.appContainerData {
  background-color: #ffffff;
  font-size: 1.1em;
}

.appDataContainerLeft,
.appDataText {
  align-items: flex-start;
}

.appDataLogo,
.appDataText,
.appDataVideo,
.appDataCarousel {
  margin-bottom: 20px;
  width: 100%;
}

.appDataLogo,
.appDataVideo,
.appDataCarousel {
  text-align: center;
}

.appDataLogo img {
  max-width: 100%;
  width: 300px;
  height: auto;
}

.appDataVideo iframe {
  width: 100%;
  max-width: 560px;
  height: 315px;
}

.appDataText ul {
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

.appDataCarouselContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appDataCarouselContent iframe {
  width: 100% !important;
  margin: 0 !important;
}

.appDataCarousel img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 400px
}

.appDataCarousel iframe {
  width: 100%;
  max-width: 560px;
  height: 100%;
  max-height: 315px;
}
/*
.appDataCarousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}*/

.appDataCarousel .thumbs-wrapper {
  display: none;
}

.appDataSection {
  width: 100%;
  max-width: 600px;
}

.appDataSection h2 {
  color: #ff725c;
  font-size: 1.5em;
  line-height: 1.1em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.appDataInputContainerCenter {
  display: flex;
  justify-content: center;
}

.appDataInputContainer button {
  background-color: #ffffff;
  font-size: 0.87em;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: .5rem 1rem;
  color: #ff725c;
  font-weight: bold;
  border: solid .25rem #eee;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-transition: opacity .15s ease-in;
  transition: opacity .15s ease-in;
}

.appDataInputContainer button:hover {
  opacity: 0.8;
}

.appDataRequired {
  font-size: .75rem;
  margin-top: 1rem;
  margin-bottom: .5rem;
  color: #808080;
  font-weight: bold;
}

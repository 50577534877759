.tox-notifications-container,
.tox-statusbar__branding {
  display: none;
}

.tox-tinymce {
  width: 100%;
  max-width: 700px;
  border-radius: 10px !important;
  margin-bottom: 20px;
}

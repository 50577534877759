#appHeader {
  flex: 0 0 auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  background-color: white;
}

#appHeaderContainer {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#appHeaderLogo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#appHeaderLogo a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#appHeaderLogo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

#appHeader h1 {
  font-weight: 500;
  font-size: 24px;
  color: #474747;
}

#appHeaderMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#appHeaderMenu ul {
  display: flex;
  align-items: center;
}

#appHeaderMenu ul li {
  display: inline;
}

#appHeaderMenu ul li + li {
  margin-left: 10px;
}

#appHeaderMenu a {
  color: #333333;
  text-decoration: none;
  font-size: 14px;
}

#appHeaderMenuIcon {
  display: none;
}

#appHeaderMenuIcon img {
  width: 30px;
}

#appHeaderMenuBack {
  display: none;
}

.appHeaderButton {
  padding: 10px 35px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 18px;
  background: #67B5E4;
  color: white !important;
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  max-width: 160px;
}

#appHeaderLanguage {
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  #appHeaderMenuIcon {
    display: inline-block;
  }

  #appHeaderMenuBack {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.35);
    transition: opacity 250ms ease-in-out,visibility 250ms ease-in-out,transform 250ms ease-in-out;
  }

  #appHeaderMenu {
    box-shadow: 1px 0 8px 0 rgba(0,0,0,0.35);
    transition: opacity 250ms ease-in-out,visibility 250ms ease-in-out,transform 250ms ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    min-width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #appHeaderMenu ul {
    display: block;
  }

  #appHeaderMenu ul li {
    display: block;
  }

  #appHeaderMenuBack:not(.appHeaderMenuVisible) {
    opacity: 0;
    visibility: hidden;
  }

  #appHeaderMenuBack.appHeaderMenuVisible {
    opacity: 1;
    visibility: visible;
  }

  #appHeaderMenu:not(.appHeaderMenuVisible) {
    opacity: 0;
    visibility: hidden;
    transform: translate(100%, 0);
  }

  #appHeaderMenu.appHeaderMenuVisible {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }

  #appHeaderMenu ul {
    width: 100%;
  }

  #appHeaderMenu ul li {
    margin-left: 0;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
    padding: 10px;
  }

  #appHeaderMenu ul li + li {
    margin-left: 0;
  }

  #appHeaderMenu ul li a {
    display: inline-block;
    width: 100%;
  }
}
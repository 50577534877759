#appPageManager {
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#appPageManager button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5BACE2;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 15px;
}

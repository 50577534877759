#appFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px;
  line-height: 22px;
  margin-top: 20px;
}

#appFooterContainer {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#appFooter h2 {
  color: black;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 25px;
}

#appFooter a {
  color: #474747;
}

#appFooter p + p,
#appFooter li + li {
  margin-top: 8px;
}

.appFooterSection {
  flex: 1 1 33%;
}

.appFooterSection li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.appFooterContactIcon {
  width: 30px;
}

.appFooterContactIcon img {
  width: 20px;
}

@media screen and (max-width: 650px) {
  #appFooterContainer {
    flex-direction: column;
  }

  .appFooterSection {
    flex: 1 1 100%;
  }

  .appFooterSection + .appFooterSection {
    margin-top: 25px;
  }

  #appFooter h2 {
    margin-bottom: 10px;
  }
}

.appFooterNetworks {
  margin-top: 30px !important;
}

.appFooterNetworks ul li {
  display: inline;
}

.appFooterNetworks ul li + li {
  margin-left: 5px;
}

.appFooterNetworks img {
  width: 30px;
  height: 30px;
}
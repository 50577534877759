.appFormField {
  width: 100%;
  background: #FCFCFC;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px 0 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.appFormField + .appFormField {
  margin-top: 20px;
}

.appFormFieldContent {
  width: 100%;
}

.appFormFieldContent label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.appFormFieldContent label img {
  margin-left: 5px;
  width: 15px;
}

.appFormFieldContent input[type="text"] {
  background-color: white;
  color: #C4C4C4 !important;
}

.appFormFieldDrag {
  width: 30px;
  cursor: pointer;
}
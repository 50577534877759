#appContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.appContainerForm {
  background-color: #f7f7f7;
}

.appFormSection {
  width: 100%;
  max-width: 850px;
}

.appFormSection + .appFormSection {
  margin-top: 50px;
  border-top: 1px solid #C4C4C4;
  padding-top: 25px;
}

.appFormSection > h2 {
  color: #eb7c66;
  font-size: 25px;
  line-height: 37px;
  margin-bottom: 20px;
}

.appFormContainer {
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.appFormInputContainer {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.appFormInputContainerOld + .appFormInputContainerOld {
  margin-top: 20px;
}

.appFormFieldContent label,
.appFormInputContainerOld label {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #797979;
}

.appFormInputContainerOld textarea:focus,
.appFormInputContainerOld input:focus,
.appFormInputContainerOld button:focus {
  outline: 0;
  border: 1px solid #5BACE2 !important;
  box-shadow: 0px 0px 5px #5BACE2;
}

.appFormInputContainerOld textarea,
.appFormFieldContent input[type="text"],
.appFormInputContainerOld input[type="text"],
.appFormInputContainerOld input[type="email"],
.appFormInputContainerOld input[type="tel"],
.appFormInputContainerOld input[type="number"] {
  width: 100%;
  height: 50px;
  border: 1px solid #a0a0a0;
  padding: 0 25px;
  border-radius: 50px;
  color: #797979;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.appFormInputContainer input[type="number"] {
  max-width: 150px;
  -moz-appearance: textfield;
}

.appFormInputContainer textarea {
  height: 200px;
  border-radius: 10px;
  resize: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.appFormInputContainer button {
  width: 100%;
  min-height: 50px;
  padding: 10px 25px;
  border-radius: 50px;
  background-color: #5BACE2;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
}

.appFormError {
  color: #e34652;
  padding-top: 10px;
}

.appFormRadioContainer {
  display: flex;
  align-items: center;
}

.appFormRadioLabel {
  margin-left: 0.5em;
  font-size: 14px !important;
  text-transform: none !important;
}

#appFormCustom {
  width: 100%;
}

#appFormCustom thead th {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #797979;
}

#appFormCustom tbody tr {
  border-top: 1px solid #a0a0a0;
}

#appFormCustom tbody th {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #797979;
}

#appFormCustom td {
  text-align: center;
  width: 100px;
}

#appFormCustom input[type="text"],
#appFormCustom input[type="tel"],
#appFormCustom input[type="email"] {
  text-align: left;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #797979;
  width: 100%;
}

#appFormAddField {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#appFormLogoButton {
  background-color: white;
  border: 1px solid #c4c4c4;
  color: #C4C4C4;
  font-size: 16px;
  max-width: 320px;
  height: 90px;
  border-radius: 20px;
  margin: 0 auto;
}

#appFormLogoImage {
  max-width: 320px;
  margin: 0 auto 20px auto;
}

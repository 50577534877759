* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Lato", sans-serif;
  color: #474747;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

h1, h2 {
  font-family: "Poppins", sans-serif;
}

#appContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#appContainer > header {
  flex: 0 0 auto;
}

#appContent {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  flex: 1 1 auto;
}

#appContentContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}


#appContainer > footer {
  flex: 0 1 auto;
}

.appHomeSection {
  width: 100%;
  max-width: 1200px;
}

/**
 * Introduction
 */

.appHomeIntro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.appHomeIntro > div {
  flex: 1 1 50%;
}

.appHomeIntro > div:last-child {
  text-align: right;
}

.appHomeIntro h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.5em;
  margin-bottom: 30px;
  color: #5A5A5A;
}

.appHomeIntroSubtitle {
  font-size: 1.5em;
}

.appHomeIntro p {
  line-height: 1.5em;
}

.appHomeIntro img {
  max-width: 100%;
  max-height: 400px;
}

.appHomeIntroButton {
  display: flex;
  width: 100%;
  max-width: 180px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.appHomeIntroButton a {
  width: 100%;
  text-align: center;
  padding: 15px 35px;
  background: #67B5E4;
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  color: white !important;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.appHomeIntroButton span {
  font-size: 12px;
  color: #44B656;
}

@media screen and (max-width: 650px) {
  .appHomeIntro {
    flex-direction: column;
  }

  .appHomeIntro > div {
    flex: 1 1 100%;
  }
}

/**
 * Apport d'affaires
 */

.appHomeFinder,
.appHomeHow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.appHomeFinder h2,
.appHomeHow h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 42px;
  color: #5A5A5A;
}

.appHomeThreePanels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.appHomeThreePanels > div {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1 1 33%;
}

.appHomeThreePanels img {
  max-width: 150px;
  margin-bottom: 20px;
}

.appHomeThreePanels h3 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;
}

.appHomeThreePanels p {
  line-height: 1.5em;
}

@media screen and (max-width: 650px) {
  .appHomeThreePanels {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .appHomeThreePanels > div {
    margin-bottom: 20px;
    flex: 1 1 100%;
  }
}

/**
 * Leads
 */

.appHomeLeads {
  margin-bottom: 40px;
}

.appHomeLeads h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #5A5A5A;
  margin-bottom: 30px;
}

.appHomeLeadPanels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.appHomeLeadPanels > div {
  flex: 1 1 33%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.appHomeLeadPanels > div > a {
  width: 100%;
  max-width: 320px;
}

@media screen and (max-width: 1000px) {
  .appHomeLeadPanels > div {
    flex: 1 1 50%;
  }
}

@media screen and (max-width: 685px) {
  .appHomeLeadPanels > div {
    flex: 1 1 100%;
  }
}

.appHomeLeadCard {
  width: 100%;
  max-width: 320px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.appHomeLeadCardImage {
  background: #C4C4C4;
  border-radius: 10px;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appHomeLeadCardImage img {
  max-width: 90%;
  max-height: 90%;
}

.appHomeLeadCardText {
  text-align: left;
  padding: 10px;
  font-size: 14px;
}

.appHomeLeadCardText p {
  line-height: 17px;
  color: #797979;
}

.appHomeLeadCardContent {
  height: 51px;
  overflow-y: hidden;
}

.appHomeLeadCard h3 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  color: #000000;
}

.appHomeLeadPrice {
  text-align: right;
}

.appHomeLeadMore {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #5bace2;
  text-decoration: underline;
}

.appHomeLeadGreen {
  color: #0CA678;
}

/**
 * Plus d'infos
 */

.appHomeMore {
  background: #EDF7FC;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  max-width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 27px;
  color: black;
}

.appHomeMore a {
  padding: 15px 35px;
  background: #67B5E4;
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  color: white !important;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 14px;
}
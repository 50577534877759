.appFormInputLabel {
  font-size: .75rem;
  margin-top: 1rem;
  margin-bottom: .5rem;
  color: #808080;
  font-weight: bold;
}

.appFormInput {
  font-size: .875rem;
  margin-bottom: .5rem;
  padding: .5rem;
  border-radius: .125rem;
  border-color: rgba(0,0,0,.1);
  border-style: solid;
  border-width: 1px;
  width: 100%;
}